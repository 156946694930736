<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-04">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header">뉴스 & 공지사항</h1>
        <div class="feature-body">
          <p>BMS 제품 관련 보도자료 및 공지사항 콘텐츠를 제공합니다.</p>
        </div>
      </div>
    </div>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb"><a href="#">BMS 소식</a></li>
            <li class="breadcrumb">뉴스 & 공지사항</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

      <div class="section">

        <!--본문 영역 s-->
        <content-area
          v-bind="board"
          file-name="BMSON-뉴스&공지사항"
          :bookmark-id="$route.params.newsNo"/>
        <!--본문 영역 e-->

        <!--list view 버튼-->
        <div class="btn_area_right btn_listview">
          <router-link to="../" append>
            <button>List View</button>
          </router-link>
        </div>

      </div>
    </div>
  </main>
</template>

<script>
import ContentArea from "@/components/common/ContentArea";
export default {
  name: "NewsView",
  components: {ContentArea},
  created() {
    this.initBoard();
  },
  data() {
    return {
      board: {
        title: ''
      },
    }
  },
  methods: {
    initBoard() {
      this.$store.dispatch('initBoard', {
        id: this.$route.params.newsNo,
        type: 'news-and-notice'
      }).then(board => {
        this.board = board
      })
    }
  },
}
</script>

<style scoped>

</style>
